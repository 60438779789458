// file name -> ManualPropmt/index.tsx

import { Icon } from "@iconify/react"
import { Button, Card } from "flowbite-react"
import { confirmAlert } from "react-confirm-alert"
type TManualPrompt = {
    doNotShowAgain: (close: () => void) => void
    alreadyInstalled: (close: () => void) => void
}
// This Prompt is only for devices which have PWA support and cannot install automatically
export const IosPropmt = async ({ doNotShowAgain, alreadyInstalled }: TManualPrompt) => {
    confirmAlert({
        customUI: ({ onClose: close }) => {
            return <Card horizontal={true} className="bg-white" style={{ width: "auto" }}>
                <div className="relative -top-4 -right-4 text-[#F62B2B]">
                    <div className="flex justify-end">
                        <Icon
                            icon="zondicons:close-solid"
                            width={20}
                            className="mr-3 h-6 sm:h-9 cursor-pointer"
                            onClick={close}
                        />
                    </div>
                </div>
                <div className="flex flex-col gap-6 -mt-12">
                    <div className="flex flex-row justify-between gap-6">
                        <div className="flex items-start -mr-6">
                            <img src="/logo512.png" alt=""  className="mr-3 h-6 sm:h-9" />
                        </div>
                        <div className="flex flex-col gap-2">
                            <h4 className="text-[18px] font-bold">
                                Cài đặt F8BET app
                            </h4>
                            <p className="text-[14px]">
                                Bạn phải cài đặt để sử dụng app, vui lòng làm theo hướng dẫn
                            </p>
                            <div className="flex justify-start">
                                {/* Prompt for ios Devices */}
                                    <div className="flex flex-col gap-2 mt-2 text-[14px]">
                                        <div className="inline-flex items-center gap-2 font-bold">
                                            1.  Nhấn vào icon <Icon icon="material-symbols:ios-share" />
                                        </div>
                                        2. Kéo xuống sau đó nhấn 
                                        <div className="inline-flex items-center gap-2 font-bold">
                                            3. Thêm vào MH chính (Add to Home Screen) <Icon icon="icon-park-outline:add" />
                                        </div>
                                    </div>
                            </div>
                        </div>
                    </div>
                    <div className="flex flex-col sm:flex-row gap-2">
                        <Button
                            color="gray"
                            className="w-full"
                            onClick={() => alreadyInstalled(close)}
                        >
                            {"Tôi đã tải rồi"}
                            <Icon
                                icon="fluent:checkbox-checked-20-filled"
                                className="ml-2 text-green-500"
                                width={20}
                            />
                        </Button>
                    </div>
                </div>
            </Card>
        },
    })
}