import React, { useEffect, useRef, useState } from 'react';
import './App.css';
import { InstallationPrompt } from './components/InstallationPrompt';
import { IosPropmt } from './components/IosPropmt';

import { APP_INSTALLED, BEFORE_INSTALL_PROMPT, INSTALLATION_MESSAGE, INSTALLATION_STATUS } from "./helpers/constants";
import { UAParser } from "ua-parser-js";
import { getDeviceInfo } from './helpers/pwa.helper';

function App() {
  const [link, setLink] = useState<any>(null);
  const [code] = useState(() => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const code = urlParams.get('a')
    if(code){
      localStorage.setItem("mhdf8", code)
      return code;
    }else {
      return localStorage.getItem("mhdf8") || ""
    }
  })
  const { device, pwa } = getDeviceInfo(new UAParser())
  const deferredPrompt = useRef<Event | null>(null)
  useEffect(() => {
    if(device.isAndroid) {
      const link = document.createElement("link");
      link.rel = "manifest";    
      link.setAttribute('href', '/manifest.json')
      document.head.appendChild(link);
      return;
    }; 
    let myDynamicManifest = {
      "short_name": "F8BET",
      "name": "F8BET",
      "start_url": `?a=${code}`,
      "icons": [
        {
          "src": "favicon.ico",
          "sizes": "64x64 32x32 24x24 16x16",
          "type": "image/x-icon"
        },
        {
          "src": "logo192.png",
          "type": "image/png",
          "sizes": "192x192"
        },
        {
          "src": "logo512.png",
          "type": "image/png",
          "sizes": "512x512"
        }
      ],
      "display": "standalone",
      "theme_color": "#000000",
      "background_color": "#ffffff"
      }
      const link = document.createElement("link");
      link.rel = "manifest";    
      const stringManifest = JSON.stringify(myDynamicManifest);
      link.setAttribute('href', 'data:application/json;charset=utf-8,' + encodeURIComponent(stringManifest))
      document.head.appendChild(link);
  },[])

  const fetchData = async () => {
  const response = await fetch("https://api.linkappf8.com/api/link?url=link_seo");
  const data = await response.json();
  const proxyUrl = `https://api.allorigins.win/get?url=${encodeURIComponent(data.link)}`; // Proxy URL
  const res = await fetch(proxyUrl);
  if(res.status == 200){
    setLink(data.link)
  }else {
    for await (const link of data.link_backup) {
      const api = proxyUrl + link; // Combine proxy URL with target URL
      const r = await fetch(api);
      if(r.ok){
        setLink(link)
        break; 
      }
    }
  }
} 

const appInstalled = () => {
  sessionStorage.setItem(INSTALLATION_STATUS, INSTALLATION_MESSAGE)
  deferredPrompt.current = null
 }
 const appInstalled2 = () => {
  localStorage.setItem(INSTALLATION_STATUS, INSTALLATION_MESSAGE)
  deferredPrompt.current = null
 }
 const doNotShowAgain = (close: () => void) => {
  appInstalled2()
  close()
 }
 const alreadyInstalled = doNotShowAgain
 const onInstall = (close: () => void) => {
  if (deferredPrompt.current) {

   // @ts-ignore
   deferredPrompt?.current.prompt();
   // Wait for the user to respond to the prompt
   let status = false
   // @ts-ignore
   deferredPrompt.current.userChoice.then((choiceResult) => {
    status = choiceResult.outcome === 'accepted'
   })
   if (status) {
    appInstalled()
    deferredPrompt.current = null;
   }
   close()
  }else {
    window.confirm("Bạn đã tải ứng dụng rồi")
  }
 }
 useEffect(() => {
  window.addEventListener(BEFORE_INSTALL_PROMPT, (e) => {
   e.preventDefault()
   deferredPrompt.current = e
  })
  window.addEventListener(APP_INSTALLED, appInstalled)
 }, [])
 useEffect(() => {
  // Trigger PWA installation prompt on mobile devices only
  const isInstallable = pwa.isStandalone(window)
  // const isInstalled = sessionStorage.getItem(INSTALLATION_STATUS)  || localStorage.getItem(INSTALLATION_STATUS) 
   if (!isInstallable){
    if (device.isAndroid) {
      InstallationPrompt({
      doNotShowAgain,
      onInstall
     })
    } 
    if(device.isIOS){
      IosPropmt({
        doNotShowAgain,
        alreadyInstalled,
       })
    }
   }else {
    if(link){
      window.location.href = link + `?a=${code}`
    }
   }
  
 }, [device.isAndroid, device.isIOS, deferredPrompt.current])

 useEffect(() => {
  fetchData()
 }, [])

 useEffect(() => {
  if(pwa.isStandalone(window) && link){
    window.location.href = link + `?a=${code}`
  }
 },[link])
  const onClickLoadPage = () => {
    if(pwa.isStandalone(window) && link){
      window.location.href = link + `?a=${code}`
    }
  }
  return (
    <div className="App" onTouchStart={onClickLoadPage}>
      <div className='logo'>
        <img src="/logo.webp" />
      </div>
      
   
    </div>
  );
}

export default App;
